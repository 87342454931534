<template>
  <div>
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <section class="agenda">
          <a-timeline>
            <span v-for="(item, i) in data" :key="i">
              <a-timeline-item color="black">
                <div
                  style="
                    border: 1px solid rgb(204, 204, 204);
                    padding: 15px;
                    background: rgb(27 27 94);
                    color: #fff;
                    margin-bottom: 18px;
                  "
                >
                  {{ moment(item.tanggal).format("dddd, DD MMMM YYYY") }}
                  -
                  {{ item.title }}
                </div>
              </a-timeline-item>

              <a-timeline-item
                color="black"
                v-for="(items, ii) in item.sub.filter((key) => key != null)"
                :key="ii"
              >
                <a-icon
                  slot="dot"
                  type="clock-circle-o"
                  style="font-size: 16px"
                />
                {{ items.jam }}
                <div v-html="items.sub_title" />
              </a-timeline-item>
            </span>
          </a-timeline>

          <!-- <a-timeline>
            <a-timeline-item color="black">
              <div
                style="
                  border: 1px solid rgb(204, 204, 204);
                  padding: 15px;
                  background: rgb(27 27 94);
                  color: #fff;
                  margin-bottom: 18px;
                "
              >
                Kamis, 22 September 2022 - Day 1 BUMN Legal Summit 2022
              </div>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              08.00 - 09.00
              <p>Registrasi</p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              09.00 - 09.10
              <p>Pembukaan Acara BUMN Legal Summit 2022</p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              09.30 - 09.45
              <p>
                Laporan dan Sambutan Bapak Carlo B. Tewu selaku Deputi Bidang
                Hukum dan Perundang-Undangan Kementerian BUMN RI
              </p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              09.50 - 10.20
              <p>Arahan Bapak Erick Thohir selaku Menteri BUMN RI</p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              10.20 - 10.50
              <p>
                Keynote Speech Bapak Mahfud MD selaku Menteri Koordinator Bidang
                Politik, Hukum dan Keamanan Indonesia
              </p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              10.30 - 11.15
              <p>Ceremonial Session</p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              13.05 - 15.00
              <p>Sharing Session I</p>
              <p>
                Narasumber:
                <strong>
                  Prof. Dr. H. Sanitiar Burhanuddin S.H., M.M., M.H., selaku
                  Jaksa Agung RI*</strong
                >
              </p>
              <p>Topik: <b>Mewujudkan BUMN Bersih dan Berdaya Saing</b></p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              15.30 - 17.00
              <p>Sharing Session II</p>
              <p>Narasumber:</p>
              <ol>
                <li>
                  <strong>
                    Dato Muhammad Salehudin selaku Head Legal Khazanah Nasional
                    Berhad*</strong
                  >
                </li>
                <li>
                  <strong>
                    Ibu Gita Tiffany Boer selaku Direktur PT Astra International
                    Tbk*
                  </strong>
                </li>
              </ol>
              <p>
                Topik: Managing and Developing Legal Function (Toward World
                Class Legal Function)
              </p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              17.00 - 17.30
              <p>Arahan Bapak Pahala Mansury selaku Wakil Menteri I BUMN RI</p>
            </a-timeline-item>

            <a-timeline-item color="black">
              <div
                style="
                  border: 1px solid rgb(204, 204, 204);
                  padding: 15px;
                  background: rgb(27 27 94);
                  color: #fff;
                  margin-bottom: 18px;
                "
              >
                Jumat, 22 September 2022 - Day 2 BUMN Legal Summit 2022
              </div>
            </a-timeline-item>

            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              08.30 - 09.00
              <p>Registrasi</p>
            </a-timeline-item>
            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              09.05 - 10.45
              <p>Sharing Session I</p>
              <p>
                Narasumber:
                <strong
                  >Bapak Mahendra Siregar, S.E., M.Ec. selaku Ketua OJK
                  RI*</strong
                >
              </p>
              <p>
                Topik: Peningkatan Pengawasan Jasa Keuangan dan Perlindungan
                Konsumen/Masyarakat
              </p>
            </a-timeline-item>
            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              10.45 - 12.00
              <p>Inspirational Talk I</p>
              <p>
                Narasumber:
                <strong
                  >Bapak Andi Widjajanto selaku Gubernur Lemhannas*</strong
                >
              </p>
              <p>
                Topik: Pemantapan Nilai Integritas, Loyalitas dan Rasa Cinta
                Tanah Air Insan Hukum BUMN
              </p>
            </a-timeline-item>
            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              13.30 - 14.45
              <p>Inspirational Talk II</p>
              <p>
                Narasumber:
                <strong
                  >Bapak Agus Martowardojo selaku Komisaris Utama BNI</strong
                >
              </p>
              <p>Topik: Peningkatan Kemampuan Kepemimpinan Insan Hukum BUMN</p>
            </a-timeline-item>
            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              14.45 - 15.15
              <p>
                Arahan Bapak Kartika Wirjoatmodjo selaku Wakil Menteri II BUMN
                RI
              </p>
            </a-timeline-item>
            <a-timeline-item color="black">
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px"
              />
              15.30 - 16.30
              <p>Penutupan Acara BUMN Legal Summit 2022</p>
            </a-timeline-item>
          </a-timeline> -->
        </section>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      data: [],
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    moment,
    fetch() {
      this.$store.dispatch("GetAllAgendaPage").then((response) => {
        this.data = response.data.data;
      });
    },
  },
};
</script>
